<div class="highlighted-products">
    <app-grid>
        <app-cell mobile="full:0">
            <div class="bg-grey"></div>
        </app-cell>

        <app-cell mobile="0:4"
                  [ngClass]="currentBreakpoint === 'laptop' || currentBreakpoint === 'desktop' ? '' : 'hidden'"
        >
            <div class="bg-grey cutoff">
                <div>
                    <h1>
                        {{ 'products.highlighted-products' | translate }}
                    </h1>
                </div>
            </div>
        </app-cell>

        <app-cell laptop="4:12" mobile="0:12">
            <div [ngClass]="currentBreakpoint === 'laptop' || currentBreakpoint === 'desktop' ? '' : 'bg-grey cutoff'">
                <div [ngClass]="(currentBreakpoint === 'laptop' || currentBreakpoint === 'desktop') ? 'hidden' : ''"
                >
                    <h2>
                        {{ 'products.highlighted-products' | translate }}
                    </h2>
                </div>

                <div class="swiper-container">
                    <div class="overlay"></div>
                    <swiper [config]="swiperOptions"
                            (swiper)="onSwiper($event)"
                            (slideChange)="onSlideChange()"
                    >
                        <ng-container *ngFor="let product of highlightedProducts">
                            <ng-template swiperSlide>
                                <div class="container" *ngIf="localization.currentLocale$ | async as locale">
                                    <div class="card" [routerLink]="'/products/' + product.slug | translateRoute">
                                        <div class="card-content">

                                            <img [src]="(product.images ?? [] | getProductImageOfType : ProductImageTypeEnum.Default).url"
                                                 [alt]="('common.image-of' | translate: { name: 'product'} )"
                                                 loading="lazy"
                                            />

                                            <div class="text-container">
                                                <div class="ellipsis">
                                                    <label class="bold text-margin stretch">
                                                        {{ product.brand }}
                                                    </label>
                                                    <label class="dark-grey stretch">
                                                        {{ product.name }}
                                                    </label>
                                                </div>

                                                <app-product-availability [statusTranslation]="product.status"
                                                                          [deliveryTimeInWeeks]="product.deliveryTimeInWeeks"
                                                ></app-product-availability>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ng-container>
                    </swiper>
                </div>

                <div class="pagination-container">
                    <p class="text-primary"
                       *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1"
                    >
                        {{swiper.swiperIndex}}/{{swiper.totalIndex}}
                    </p>
                    
                    <div #pagination></div>
                    
                    <div *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1" class="arrow-container">
                        <span appIcon="arrow-left"
                            class="navigation-icon"
                            [ngClass]="swiper.swiper.isBeginning ? 'disabled' : 'enabled'"
                            (click)="swiper.slidePrev()"
                        ></span>

                        <span appIcon="arrow-right"
                              class="navigation-icon"
                              [ngClass]="swiper.swiper.isEnd ? 'disabled' : 'enabled'"
                              (click)="swiper.slideNext()"
                        ></span>
                    </div>
                </div>
            </div>
        </app-cell>
    </app-grid>
</div>


