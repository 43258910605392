import {
    Widget,
    WidgetDescription,
    Connector,
    Unmounter,
    Renderer,
    UnknownWidgetParams,
} from 'instantsearch.js/es/types'

import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

import { noop } from '@app-lib/common.lib'
import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'

export { Widget, Connector }

type AdditionalWidgetProperties = Partial<Widget<WidgetDescription>>

@Component({
    template: '',
})
export abstract class AbstractInstantsearchWidgetComponent<
    TWidgetDescription extends WidgetDescription,
    TConnectorParams extends UnknownWidgetParams,
> implements OnInit, OnDestroy {

    @Input()
    public autoHideContainer?: boolean

    // Todo: remove non-null assertion
    public widget?: Widget

    public state?: TWidgetDescription['renderState']

    public cx(element?: string, subElement?: string) {
        let cssClass = `ais-${this.widgetName}`
        if (element) {
            cssClass += `-${element}`
        }
        if (subElement) {
            cssClass += `--${subElement}`
        }
        return cssClass
    }

    public abstract readonly widgetName: string
    public abstract instantSearchInstance: AlgoliaInstantsearchComponent

    public createWidget(
        connector: Connector<TWidgetDescription, TConnectorParams>,
        options: TConnectorParams,
        additionalWidgetProperties: AdditionalWidgetProperties = {},
    ) {
        this.widget = {
            ...connector(this.updateState, noop as Unmounter)(options),
            ...additionalWidgetProperties,
        }
    }

    public ngOnInit() {
        this.instantSearchInstance.addWidgets([this.widget!]) // Todo: remove non-null assertion
    }

    public ngOnDestroy() {
        if (isPlatformBrowser(this.instantSearchInstance.platformId)) {
            this.instantSearchInstance.removeWidgets([this.widget!]) // Todo: remove non-null assertion
        }
    }

    public updateState: Renderer<TWidgetDescription['renderState'], TConnectorParams> = (state, isFirstRendering) => {
        if (isFirstRendering) {
            Promise.resolve()
                .then(() => this.state = state)
        } else {
            this.state = state
        }
    }

    /**
     * Helper to generate class names for an item
     * @param item element to generate a class name for
     */
    public getItemClass(item: { isRefined?: boolean }): string {
        const className = this.cx('item')

        if (item.isRefined) {
            return `${className} ${this.cx('item', 'selected')}`
        }

        return className
    }

}
