<ng-container *ngIf="shouldSlide$ | async; else noSlider">
    <div class="swiper-container">
        <div class="overlay"></div>
        <swiper *ngIf="swiperOptions$ | async as swiperOptions"
                [config]="swiperOptions"
                (swiper)="onSwiper($event)"
                (slideChange)="onSlideChange()"
        >
            <ng-container *ngFor="let paragraph of paragraphCarousels">
                <ng-template swiperSlide>
                    <ng-container [ngTemplateOutlet]="paragraphTemplate" [ngTemplateOutletContext]="{ p: paragraph }"></ng-container>
                </ng-template>
            </ng-container>
        </swiper>
    </div>

</ng-container>

<div class="pagination-container" [class.hidden]="(shouldSlide$ | async) === false">
    <p *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1">
        {{swiper.swiperIndex}}/{{swiper.totalIndex}}
    </p>
    
    <div #pagination></div>

    <div *ngIf="swiper && swiper.totalIndex && swiper.totalIndex > 1">
        <span appIcon="arrow-left" 
              class="navigation-icon"
              [ngClass]="swiper.swiper.isBeginning ? 'disabled' : 'enabled'"
              (click)="swiper.slidePrev()"
        ></span>

        <span appIcon="arrow-right"
              class="navigation-icon"
              [ngClass]="swiper.swiper.isEnd ? 'disabled' : 'enabled'"
              (click)="swiper.slideNext()"
        ></span>
    </div>
</div>

<ng-template #paragraphTemplate let-p="p">
    <div class="paragraph-container" *ngIf="p | asParagraphFragment as paragraph">
        <div class="card">
            <h3 class="text-primary">
                {{paragraph.title}}
            </h3>
            <p class="small text-grey-5">
                {{paragraph.paragraph}}
            </p>
        </div>
    </div>
</ng-template>

<ng-template #noSlider>
    <div class="no-carousel">
        <ng-container *ngFor="let paragraph of paragraphCarousels">
            <ng-container [ngTemplateOutlet]="paragraphTemplate" [ngTemplateOutletContext]="{ p: paragraph }"></ng-container>
        </ng-container>
    </div>
</ng-template>
