<div class="auth-callback-container" @fadeIn>
    <h1>{{ 'auth.logging-in' | translate }}</h1>

    <div *ngIf="exchangeState === 'pending'">
        <app-spinner [size]="64"></app-spinner>
    </div>

    <div *ngIf="exchangeState === 'success'" @fadeIn>
        <span class="state-icon state-icon--success" [appIcon]="'check-circle'"></span>
    </div>

    <div *ngIf="exchangeState === 'failure'" @fadeIn>
        <span class="state-icon state-icon--failure" [appIcon]="'error-circle'"></span>
    </div>
</div>
