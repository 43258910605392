import { startWith, Subscription, combineLatestWith } from 'rxjs'
import { distinctUntilChanged, filter } from 'rxjs/operators'

import { Injectable, Inject, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Analytics } from '@app-domains/analytics/types/analytics.types'
import { WINDOW } from '@ng-web-apis/common'
import { NavigationEnd, Router } from '@angular/router'

import { Localization } from '@aa-app-localization'
import { ShoppingCartLineFragment, ProductCategoryFragment } from '@app-graphql/schema'
import { AuthService } from '@app-services'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'

import DataLayerItem = Analytics.DataLayerItem
import PItem = Analytics.PItem
import CheckoutStep = Analytics.CheckoutStep

declare const dataLayer: any[]

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {

    private isLoaded = false
    private renderer: Renderer2
    private pItemsChuckSize = 20
    private subscriptions$: Subscription

    private currencySymbol = 'EUR'

    constructor(
        @Inject(PLATFORM_ID) protected platformId: string,
        @Inject(DOCUMENT) private document: Document,
        @Inject(WINDOW) private window: Window,
        @Inject('analyticsConfig')
        private config: Analytics.Config,
        private rendererFactory: RendererFactory2,
        private router: Router,
        private authService: AuthService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null)
    }

    public setGoogleAnalyticsScript(): void {
        if (isPlatformBrowser(this.platformId) && ! this.isLoaded) {
            const script1: HTMLScriptElement = this.renderer.createElement('script')
            script1.setAttribute('async', '')
            script1.src = `https://www.googletagmanager.com/gtm.js?id=${this.config.trackingId}`
            this.renderer.appendChild(this.document.body, script1)

            const script2: HTMLScriptElement = this.renderer.createElement('script')
            script2.text = `
            window.dataLayer = window.dataLayer || [];function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date()); gtag('config', '${this.config.trackingId}');
            `
            this.renderer.appendChild(this.document.body, script2)

            this.isLoaded = true
        }
    }

    public startRouteLogging(): void {
        if (this.subscriptions$) {
            return
        }

        const events$ = this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            startWith({
                url: this.window.location.pathname,
                type: 1,
                id: 0,
                urlAfterRedirects: this.window.location.pathname,
            } as NavigationEnd))

        const user$ = this.authService.user$

        this.subscriptions$ = events$.pipe(
            combineLatestWith(user$),
            distinctUntilChanged(),
        ).subscribe(([events, user]) => this.logPageLoad(events.url, events.url, user?.id))
    }

    public sendEvent(gtmTag: DataLayerItem | Analytics.EcommerceReset | Analytics.CheckoutStep): void {
        if (isPlatformBrowser(this.platformId)) {
            dataLayer.push(gtmTag)
        }
    }

    public transformProductsToPItem(
        locale: Localization.Locale,
        products: Product[],
    ): PItem[] {
        return products.map((p: any) => this.transformProductToPItem(locale, p))
    }

    public transformProductToPItem(
        locale: Localization.Locale,
        product: Product,
    ): PItem {
        const pItem: PItem = {
            item_id: product.ean,
            item_name: product.name,
            currency: this.currencySymbol,
            quantity: 1,
            index: 1,
        }

        // When the product comes from product query we have full categories
        if (product.categories && product.categories.length) {
            product.categories.forEach((cat: ProductCategoryFragment, index: number) => {
                const catProp = `item_category${(index === 0 ? '' : 1 + index)}`
                pItem[catProp] = cat.translations[locale]?.name
            })
        }

        // When the product comes from Algolia we only have array of category names
        if (product.categoryNames) {
            product.categoryNames.forEach((cat: string, index: number) => {
                const catProp = `item_category${(index === 0 ? '' : 1 + index)}`
                pItem[catProp] = cat
            })
        }

        if (product.brand) {
            pItem.item_brand = product.brand ?? ''
        }

        if (product.variants) {
            pItem.item_variant = product.variants.types[0]?.translations[locale]?.slug ?? ''
        }

        if (product.suggestedRetailPrice) {
            pItem.price_suggestedRetail = product.suggestedRetailPrice
            pItem.price_purchase = product.debtorPrice ?? product.suggestedRetailPrice
        }

        if (product.quantity) {
            pItem.quantity = product.quantity
        }

        if (product.index) {
            pItem.index = product.index
        }

        if (product.currency) {
            pItem.currency = product.currency
        }

        if (product.listName) {
            pItem.item_list_name = product.listName
        }

        return pItem
    }

    /**
     * Log each page load
     */
    public logPageLoad(url: string, title: string, userId: string | null = null): void {
        const gtmTag: DataLayerItem = {
            event: 'page_load',
            page_type: url,
            blog_title: title,
        }

        if (userId) {
            gtmTag.user_id = userId
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Login and signup
     */
    public logLogin(user_id: string): void {
        const gtmTag: DataLayerItem = {
            event: 'login',
            user_id,
        }

        this.sendEvent(gtmTag)
    }

    public logSignup(): void {
        const gtmTag: DataLayerItem = {
            event: 'signup',
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Product impressions
     */
    public logProductList(input: PItem[], listType: Analytics.ListType, page: number = 1): void {
        this.resetEcommerceTag()

        const items = input.map((i, index) => {
            i.item_list_name = listType
            i.index = index + (page - 1) * 20
            return i
        }).slice(0, this.pItemsChuckSize)

        const gtmTag: DataLayerItem = {
            event: 'view_item_list',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    public logProductClick(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'select_item',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    public logProductView(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'view_item',
            ecommerce: {
                currency: this.currencySymbol,
                items: items,
            },
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Shopping cart
     */
    public logCart(locale: Localization.Locale, cartLines: ShoppingCartLineFragment[], action: string): void {
        const pItems: PItem[] = cartLines.map((scf) => {
            return {
                item_id: scf.product!.ean,
                item_name: scf.product!.translations[locale].name,
                item_brand: scf.product!.brand?.name ?? '',
                quantity: scf.quantity,
                currency: this.currencySymbol,
                price: scf.price,
            }
        })

        switch (action) {
            case 'view':
                this.logCartView(pItems)
                break
            case 'add':
                this.logCartAdd(pItems)
                break
            case 'remove':
                this.logCartRemove(pItems)
                break
        }
    }

    public logCartView(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'view_cart',
            checkout_step: '1',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    public logCartAdd(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'add_to_cart',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    public logCartRemove(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'remove_from_cart',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Wishlist
     */
    public logWishlistAdd(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'add_to_wishlist',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    public logWishlistRemove(items: PItem[]): void {
        this.resetEcommerceTag()

        const gtmTag: DataLayerItem = {
            event: 'remove_from_wishlist',
            ecommerce: {
                currency: this.currencySymbol,
                items,
            },
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Filters & Sorting
     */
    public logFilterOn(
        filterType: Analytics.FilterTypes,
        filterName: string,
        filterValue1: string,
        filterValue2: string = '',
    ): void {
        const gtmTag: DataLayerItem = {
            event: 'filter_on',
            filter_type: filterType,
        }

        if (filterType === 'checkbox') {
            gtmTag.filter_name = filterName
            gtmTag.filter_value = filterValue1
        } else {
            gtmTag.filter_name = filterName
            gtmTag.filter_value_low = filterValue1
            gtmTag.filter_value_high = filterValue2
        }

        this.sendEvent(gtmTag)
    }

    public logFilterOff(
        filterType: Analytics.FilterTypes,
        filterName: string,
        filterValue: string,
    ): void {
        const gtmTag: DataLayerItem = {
            event: 'filter_off',
            filter_type: filterType,
            filter_name: filterName,
            filter_value: filterValue,
        }

        this.sendEvent(gtmTag)
    }

    public logSortOn(sortValue: string): void {
        const gtmTag: DataLayerItem = {
            event: 'sort_change',
            sort_value: sortValue,
        }

        this.sendEvent(gtmTag)
    }

    /**
     * Checkout
     */
    public logCheckoutStep(
        items: PItem[],
        checkoutStep: number,
        event: 'begin_checkout' | 'add_shipping_info' | 'add_payment_info' | 'purchase',
        shippingTier: 'delivery' | 'dropshipment',
        value: number = 0,
        shipping: number = 0,
    ): void {
        if ( event !== 'purchase' ) {
            const chkTag: CheckoutStep = {
                event: event,
                checkout_step: checkoutStep,
                shipping_tier: shippingTier,
                ecommerce: {
                    currency: this.currencySymbol,
                    items,
                },
            }
            this.sendEvent(chkTag)
        } else {
            const purchaseTag: DataLayerItem = {
                event: event,
                ecommerce: {
                    currency: this.currencySymbol,
                    value,
                    shipping,
                    items,
                },
            }
            this.sendEvent(purchaseTag)
        }
    }

    /**
     * Reset the ecommerce object to prevent memory effects
     */
    private resetEcommerceTag(): void {
        const gtmTagReset: Analytics.EcommerceReset = {
            ecommerce: null,
        }

        this.sendEvent(gtmTagReset)
    }
}
